import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { DATA_FEED_URL } from 'constants/api/apiServices';

export const INVALIDATION_TAGS = {
  SETUP: 'setup',
  GET_USERS: 'getUsers',
  GET_PRODUCT_REPORT: 'getProductReport',
  GET_PRODUCT_PLAYERS_STATISTICS: 'getProductPlayerStatistics',
  GET_COUNTRIES_REPORT_LIST: 'getCountryByCountryReport',
  GET_MONTHLY_REPORT_LIST: 'getMonthByMonthReport',
  GET_MEDIA_STATISTICS_PRO: 'getMediaStatisticsPro',
  GET_REVENUE_BREAKDOWN_LIST: 'getRevenueBreakdownReport',
  GET_PRODUCT_AFFILIATE_STATISTICS_SUB: 'getProductStatisticsByAffiliateSub',
  GET_PRODUCT_STATISTICS_SUB: 'getProductStatisticsSub',
  GET_PRODUCT_STATISTICS: 'getProductStatistics',
  GET_PRODUCT_STATISTICS_DAILY: 'getProductStatisticsDaily',
  GET_PRODUCT_STATISTICS_DAILY_SUB: 'getProductStatisticsDailySub',
  GET_SUB_AFFILIATE_STATISTICS: 'getSubAffiliateStatistic',
  GET_AGENT_WALLET_INFO_AFTER_TRANSACTION: 'getAgentWalletInfoAfterTransaction',
  GET_PLAYER_WALLET_INFO_AFTER_TRANSACTION: 'getPlayerWalletInfoAfterTransaction',
  GET_BET_ODDS: 'getBetOdds',
  GET_AGENT_WALLET: 'getAgentWallet',
  GET_AGENT_ALL_WALLETS: 'getAgentAllWallets',
  GET_ALL_POSITIVE_AVAILABLE_WALLETS: 'getAllPositiveAvailableWallets',
  GET_SUB_AGENT_STATISTIC: 'getSubAgentStatistic',
  GET_PRODUCTS_LOG: 'getPartnerProductsLogs',
  GET_AFFILIATE_LIFE_TIME_LOG: 'getAffiliateLifeTimeLog',
  GET_CARRY_OVER_LOG_LIST: 'getCarryOverLogByAffiliate',
  GET_WITHDRAW_SUB_USER_LIST: 'getWithdrawListForChildren',
  GET_MY_REQUESTS_USER_LIST: 'getMyRequestsUserList',
  GET_COMMISSION_PLAN_LOG: 'getCommissionPlanLogs',
  GET_AGENT_WALLET_BY_AGENT_ID: 'getAgentWalletByAgentId',
  GET_PLAYERS_TRANSFER_LIST: 'getPlayersTransferList',
  GET_AGENT_TRANSACTION_LIST: 'getAgentTransactionList',
  GET_AGENTS_TRANSFER_LIST: 'getAgentsTransferList',
  GET_PLAYERS_BALANCE_BY_ID: 'getPlayersBalanceById',
  GET_ALL_PRODUCTS: 'getAllProducts',
  GET_All_LANGUAGES: 'getAllLanguages',
  GET_ALL_CURRENCIES: 'getAllCurrencies',
  GET_AFFILIATE_PAYMENT_SYSTEMS: 'getAffiliatePaymentSystems',
  GET_AVAILABLE_BANNERS: 'getAvailableBanners',
  GET_MY_BANNERS: 'getMyBanners',
  GET_SUB_USER_BANNERS: 'getSubUserBanners',
  GET_ACTIVE_BANNERS: 'getActiveBanners',
  GET_PARTNER_BRANDS: 'getPartnerBrands',
  GET_MARKETING_SOURCES: 'getMarketingSourcesList',
  GET_LANDING_PAGES_LIST: 'getLandingPageList',
  GET_LINKS: 'getLinks',
  GET_SOCIAL_SHARE_LIST: 'getSocialShareList',
  GET_LATEST_NEWS: 'getLatestNews',
  GET_DELETED_PLAYERS_LIST: 'getDeletedPlayersList',
  GET_PLAYERS_STATISTICS_PRO_LIST: 'getActivePlayersList',
  GET_PLAYER_LIMIT: 'getPlayerLimit',
  TRANSFER_PLAYER_LOG: 'transferPlayerLog',
  GET_ADDED_PLAYERS_LIST: 'getAddedPlayersList',
  GET_GROUPS_LIST: 'getGroups',
  GET_AFFILIATES_LIST_BY_GROUP_ID: 'getUsersListByGroupId',
  GET_TESTIMONIALS: 'getTestimonials',
  GET_DOMAINS_URL: 'getDomainsURL',
  GET_ADDITIONAL_DOMAINS: 'getAdditionalDomains',
  GET_DOMAIN_NAMES: 'getDomainNames',
  GET_ALL_HYBRIDS: 'getAllHybrids',
  GET_FILE_HUB_LIST: 'getFileHubList',
  GET_PRODUCTS_COST_LOG: 'getProductsCostLog',
  GET_CURRENT_LANG_DICTIONARY_ITEM: 'getCurrentLangDictionaryItem',
  GET_PAYMENT_FTD_RESTRICTION: 'getFtdRestrictions',
  GET_PAYMENT_MIN_MAX_RANGE: 'getWithdrawalLimits',
  GET_AFFILIATE_COMMISSION: 'getCommissionByUser',
  GET_CURRENT_СPA: 'getCurrentCpa',
  GET_AFFILIATE_REFERRAL_COMMISSION: 'getReferralCommissionByUser',
  GET_COMMISSION_TYPE_NAMES: 'getCommissionTypeNames',
  GET_PARTNER_BONUS_COST: 'getPartnerBonusCost',
  GET_VERIFICATION_DOC: 'getVerificationDoc',
};

export const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({ baseUrl: '/' }),
  tagTypes: Object.values(INVALIDATION_TAGS),
  endpoints: () => ({}),
});

export const feedApi = createApi({
  reducerPath: 'feedApi',
  baseQuery: fetchBaseQuery({ baseUrl: DATA_FEED_URL }),
  tagTypes: [],
  endpoints: () => ({}),
});
