import { api, INVALIDATION_TAGS } from 'reduxStore/api';
import {
  ADD_AFFILIATE_NOTE,
  CALCULATE_NOW,
  CHANGE_ONE_TIME_PASSWORD,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_ACCOUNT,
  GET_ACTIVITY_STATISTIC_BY_USERS_LIST,
  GET_AFFILIATES_LIST,
  GET_AFFILIATES_SHORT_INFO,
  LAST_CALCULATE_DATE,
  REGISTRATION,
  REMOVE_AFFILIATE,
  SET_AFFILIATES_STATUS_BULK,
  UPDATE_AFFILIATE_ROLE,
  UPDATE_AFFILIATE_STATUS,
  UPLOAD_STATUS_CHANGE_LIST_BY_FILE,
  GET_UPLOADED_STATUS_CHANGE_DATA_LIST,
  SET_AFFILIATE_STATUS_BY_FILE,
  PHONE_NUMBER_VERIFICATION_CODE,
  UPDATE_AVAILABLE_WALLET,
  GET_AFFILIATE_LIFE_TIME,
  SET_AFFILIATE_LIFE_TIME,
  GET_PARTNER_BONUS_COST,
  SET_CONVERTED_BONUS_COST,
  RESET_TWO_FACTOR_AUTH_SECRET,
  GET_VERIFICATION_DOC,
  SAVE_VERIFICATION_DOCUMENT,
} from 'constants/api';
import { logsApi } from 'reduxStore/services/logsApi';

export const userApi = api.injectEndpoints({
  endpoints: builder => ({
    createUser: builder.mutation({
      query: credentials => ({
        url: REGISTRATION,
        method: 'POST',
        body: credentials,
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        if (data?.result) {
          dispatch(userApi.util.invalidateTags([INVALIDATION_TAGS.GET_USERS]));
        }
      },
    }),
    getUsers: builder.query({
      query: credentials => ({
        url: GET_AFFILIATES_LIST,
        method: 'POST',
        body: credentials,
      }),
      providesTags: [INVALIDATION_TAGS.GET_USERS],
    }),
    removeUser: builder.mutation({
      query: credentials => ({
        url: REMOVE_AFFILIATE,
        method: 'POST',
        body: credentials,
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        if (data?.result) {
          dispatch(userApi.util.invalidateTags([INVALIDATION_TAGS.GET_USERS]));
        }
      },
    }),
    addUserNote: builder.mutation({
      query: credentials => ({
        url: ADD_AFFILIATE_NOTE,
        method: 'POST',
        body: credentials,
      }),
      onQueryStarted: async (credentials, { dispatch, getState, queryFulfilled }) => {
        const { data } = await queryFulfilled;

        const { filterParams } = getState().filter;
        const { affiliateId, note } = credentials;
        if (data?.result) {
          dispatch(
            userApi.util.updateQueryData('getUsers', filterParams, draft => {
              draft.result.records = draft.result.records.map(item =>
                item.affiliateId === affiliateId ? { ...item, note } : item
              );
            })
          );
        }
      },
    }),
    getPartnerLastCalculationDate: builder.query({
      query: () => ({
        url: LAST_CALCULATE_DATE,
        method: 'POST',
      }),
    }),
    calculateNow: builder.mutation({
      query: credentials => ({
        url: CALCULATE_NOW,
        method: 'POST',
        body: credentials,
      }),
    }),
    setUserStatusBulk: builder.mutation({
      query: ({ statusId, affiliateIds }) => ({
        url: SET_AFFILIATES_STATUS_BULK,
        method: 'POST',
        body: {
          affiliateList: affiliateIds,
          statusId,
        },
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        if (data?.result) {
          dispatch(userApi.util.invalidateTags([INVALIDATION_TAGS.GET_USERS]));
        }
      },
    }),
    updateUserStatus: builder.mutation({
      query: body => ({
        url: UPDATE_AFFILIATE_STATUS,
        method: 'POST',
        body,
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        if (data?.result) {
          dispatch(userApi.util.invalidateTags([INVALIDATION_TAGS.GET_USERS]));
          dispatch(logsApi.util.invalidateTags([INVALIDATION_TAGS.GET_COMMISSION_PLAN_LOG]));
        }
      },
    }),
    updateUserRole: builder.mutation({
      query: body => ({
        url: UPDATE_AFFILIATE_ROLE,
        method: 'POST',
        body,
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        if (data?.result) {
          dispatch(userApi.util.invalidateTags([INVALIDATION_TAGS.GET_USERS]));
          dispatch(logsApi.util.invalidateTags([INVALIDATION_TAGS.GET_COMMISSION_PLAN_LOG]));
        }
      },
    }),
    getAffiliateShortInfo: builder.query({
      query: body => ({
        url: GET_AFFILIATES_SHORT_INFO,
        method: 'POST',
        body,
      }),
      transformResponse: response => {
        const { result } = response;
        if (!Array.isArray(result)) {
          const {
            totals: { total },
          } = result;

          return total;
        }
      },
    }),
    getActivityByUsers: builder.query({
      query: body => ({
        url: GET_ACTIVITY_STATISTIC_BY_USERS_LIST,
        method: 'POST',
        body,
      }),
    }),
    passwordChange: builder.mutation({
      query: body => {
        const apiEndpoint = body.type
          ? body.isAgent
            ? CHANGE_PASSWORD
            : CHANGE_PASSWORD_ACCOUNT
          : CHANGE_ONE_TIME_PASSWORD;

        return {
          url: apiEndpoint,
          method: 'POST',
          body: body.password,
        };
      },
    }),
    changeStatusByFile: builder.mutation({
      query: body => ({
        url: UPLOAD_STATUS_CHANGE_LIST_BY_FILE,
        method: 'POST',
        body,
      }),
    }),
    getStatusChangeList: builder.query({
      query: body => ({
        url: GET_UPLOADED_STATUS_CHANGE_DATA_LIST,
        method: 'POST',
        body,
      }),
      transformResponse: response => response.result,
    }),
    setAffiliateStatusByFile: builder.mutation({
      query: statusId => ({
        url: SET_AFFILIATE_STATUS_BY_FILE,
        method: 'POST',
        body: { statusId },
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        if (data?.result) {
          dispatch(userApi.util.invalidateTags([INVALIDATION_TAGS.GET_USERS]));
        }
      },
    }),
    phoneNumberVerificationCode: builder.query({
      query: body => ({
        url: PHONE_NUMBER_VERIFICATION_CODE,
        method: 'POST',
        body,
      }),
    }),
    updateAvailableWallet: builder.mutation({
      query: body => ({
        url: UPDATE_AVAILABLE_WALLET,
        method: 'POST',
        body,
      }),
    }),
    getAffiliateLifeTime: builder.query({
      query: body => ({
        url: GET_AFFILIATE_LIFE_TIME,
        method: 'POST',
        body,
      }),
    }),
    setAffiliateLifeTime: builder.mutation({
      query: body => ({
        url: SET_AFFILIATE_LIFE_TIME,
        method: 'POST',
        body,
      }),
      onQueryStarted: async (body, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        const { affiliateId, period, periodCpa } = body;

        if (data?.result) {
          dispatch(logsApi.util.invalidateTags([INVALIDATION_TAGS.GET_AFFILIATE_LIFE_TIME_LOG]));
          dispatch(
            userApi.util.updateQueryData('getAffiliateLifeTime', { affiliateId }, draft => {
              draft.result = { period, periodCpa };
            })
          );
        }
      },
    }),
    getPartnerBonusCost: builder.query({
      query: body => ({
        url: GET_PARTNER_BONUS_COST,
        method: 'POST',
        body,
      }),
      providesTags: [INVALIDATION_TAGS.GET_PARTNER_BONUS_COST],
    }),
    setPartnerBonusCost: builder.mutation({
      query: body => ({
        url: SET_CONVERTED_BONUS_COST,
        method: 'POST',
        body,
      }),
      onQueryStarted: async (body, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        const { affiliateId } = body;

        if (data?.result) {
          dispatch(
            userApi.util.updateQueryData('getPartnerBonusCost', { affiliateId }, draft => {
              draft.result.records[0].percent = parseFloat(body.percent).toFixed(2);
            })
          );
        }
      },
    }),
    resetTwoFactorAuth: builder.mutation({
      query: body => ({
        url: RESET_TWO_FACTOR_AUTH_SECRET,
        method: 'POST',
        body,
      }),
    }),
    getVerificationDoc: builder.query({
      query: body => ({
        url: GET_VERIFICATION_DOC,
        method: 'POST',
        body,
      }),
      providesTags: [INVALIDATION_TAGS.GET_VERIFICATION_DOC],
    }),
    saveVerificationDoc: builder.mutation({
      query: body => ({
        url: SAVE_VERIFICATION_DOCUMENT,
        method: 'POST',
        body,
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;

        if (data?.result) {
          dispatch(api.util.invalidateTags([INVALIDATION_TAGS.GET_VERIFICATION_DOC]));
        }
      },
    }),
  }),
});

export const {
  useCreateUserMutation,
  useCalculateNowMutation,
  useUpdateUserStatusMutation,
  useUpdateUserRoleMutation,
  useAddUserNoteMutation,
  useRemoveUserMutation,
  usePasswordChangeMutation,
  useLazyGetUsersQuery,
  useGetActivityByUsersQuery,
  useGetAffiliateShortInfoQuery,
  useChangeStatusByFileMutation,
  useLazyGetStatusChangeListQuery,
  useSetUserStatusBulkMutation,
  useLazyGetPartnerLastCalculationDateQuery,
  useSetAffiliateStatusByFileMutation,
  useLazyPhoneNumberVerificationCodeQuery,
  useUpdateAvailableWalletMutation,
  useGetAffiliateLifeTimeQuery,
  useSetAffiliateLifeTimeMutation,
  useGetPartnerBonusCostQuery,
  useSetPartnerBonusCostMutation,
  useResetTwoFactorAuthMutation,
  useGetVerificationDocQuery,
  useSaveVerificationDocMutation,
} = userApi;
